import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#37474F'
        },
        secondary: {
            main: '#37474F'
        }
    },
    components: {
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    '&.RaMenuItemLink-active': {
                        color: '#37474F !important',
                        backgroundColor: '#F5F5F5 !important',
                        '& .RaMenuItemLink-icon': {
                            color: '#37474F !important'
                        }
                    }
                }
            }
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    '& .RaLayout-content': {
                        marginTop: '16px'
                    }
                }
            }
        }
    }
});

export default theme;
