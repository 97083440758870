import { useState, useEffect } from 'react';
import { useAuthenticated } from 'react-admin';
import { useParams, useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { acteurData } from '../acteurs';
import { organeData } from '../organes';
import { scrutinData } from '../scrutins';
import { dossierData } from '../dossiers';
import { documentData } from '../documents';
import { questionData } from '../questions';
import { amendementData } from '../amendements';
import { interventionData } from '../interventions';
import { reunionData } from '../reunions';
import { MultiList } from './MultiList';
import { Show } from '../components/show';
import { AmendementShow } from '../amendements';
import cloneDeep from 'lodash/cloneDeep';

export const Search = ({ dataset }) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [isDrawerOpen, setIsDrawerOpen] = useState(Boolean(dataset && id))

    const datasets = {
        'dossiers': cloneDeep(dossierData),
        'acteurs': cloneDeep(acteurData),
        'amendements': cloneDeep(amendementData),
        'scrutins': cloneDeep(scrutinData),
        'reunions': cloneDeep(reunionData),
        'interventions': cloneDeep(interventionData),
        'documents': cloneDeep(documentData),
        'questions': cloneDeep(questionData),
        'organes': cloneDeep(organeData)
    }

    useAuthenticated()

    useEffect(() => {
        if (dataset && id) {
            setIsDrawerOpen(true)
        }
    }, [dataset, id])

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false)
        navigate(`/parliament/amendements`);
    }

    return (
        <>
            <MultiList 
                initialDataset={dataset} 
                datasets={datasets} 
                navigateOnChange={true}
                showSearch={true} 
                height='90vh' 
            />
            <Drawer
                open={isDrawerOpen}
                anchor="right"
                sx={{ zIndex: 100 }}
                onClose={handleCloseDrawer}
            >
                <Show
                    resource={dataset}
                    includes={["acteurRef.groupeParlementaire", "texteLegislatifRef.dossierRef"]}
                >
                    <AmendementShow />
                </Show >
            </Drawer>
        </>
    )
};
