import React, { useRef } from 'react';
import { List, Datagrid, TextField, DateField, NumberField, TopToolbar, useTranslate, useListContext, FunctionField } from 'react-admin';
import ExportButton from '../utils/ExportButton';
import UserFilter from './UserFilter';
import FullNameField from '../inputs/FullNameField';
import SectionTitle from '../utils/SectionTitle';
import CustomPagination from '../utils/CustomPagination';

const UserListActions = () => {
    const { filterValues, sort } = useListContext();

    return (
        <TopToolbar>
            <ExportButton dataName={"User"} filters={filterValues} sort={sort} />
        </TopToolbar>
    );
};

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

const UserList = (props) => {
    const translate = useTranslate();
    const pageTokens = useRef({});

    return (
        <>
            <SectionTitle title={translate("resources.users.section_title")} subtitle={translate("resources.users.section_subtitle")} />
            <List
                title={translate('resources.users.name', { smart_count: 2 })}
                filters={<UserFilter />}
                filter={{ countless: true }}
                filterDefaultValues={{ created_at_gte: oneMonthAgo.toISOString().split('T')[0] }}
                sort={{ field: 'created_at', order: 'DESC' }}
                actions={<UserListActions />}
                disableSyncWithLocation
                queryOptions={{ meta: { pageTokens }}}
                pagination={<CustomPagination alwaysOn />}
                {...props}
            >
                <Datagrid rowClick="edit" bulkActionButtons={null} >
                    <TextField source="identifier" label="ID" />
                    <FunctionField render={record => <FullNameField user={record} />} />
                    <TextField source="points" label={translate('resources.users.fields.points')} />
                    <NumberField source="moderation_score" />
                    <NumberField source="messages_count" />
                    <NumberField source="votes_count" />
                    <NumberField source="proposals_count" />
                    <NumberField source="debate_suggestions_count" />
                    <DateField source="created_at" showTime={true} />
                </Datagrid>
            </List>
        </>
    );
};

export default UserList;
