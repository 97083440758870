import React from 'react';
import '../global.scss';
import { ModerableSourceDashboardBox } from '../moderable_sources/ModerableSourceDashboardBox';
import { PostDashboardBox } from '../posts/PostDashboardBox';
import styles from './Dashboard.module.scss';
import { Box, Stack } from '@mui/material';
import { useGetList, useTranslate } from 'react-admin';
import { ModerableSource } from '../moderable_sources/moderableSource';
import emptyBoxImg from '../assets/box-empty.svg';
import { CreateModerableSourceDialog } from '../moderable_sources/CreateModerableSourceDialog';
import { Add } from '@mui/icons-material';
import { StatsDashboardBox } from '../statistics/StatsDashboardBox';

export function Dashboard() {
    const translate = useTranslate();
    const { data: sources, isLoading } = useGetList<ModerableSource>(
        'moderable_sources',
        {},
        { staleTime: 5 * 60 * 1000 }
    );
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    if (isLoading) {
        return null;
    }

    if (sources && sources.length === 0) {
        return (
            <>
                <Stack
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80vh',
                        gap: 3
                    }}
                >
                    <img src={emptyBoxImg} alt="no data" width={300} style={{ opacity: 0.8 }} />
                    <h1>{translate('pos.social_networks.no_source.title')}</h1>
                    <p>{translate('pos.social_networks.no_source.description')}</p>

                    <button className="btn btn-primary" onClick={() => setDialogOpen(true)}>
                        <Add />
                        {translate('pos.social_networks.actions.add_source')}
                    </button>
                </Stack>
                <CreateModerableSourceDialog open={isDialogOpen} handleClose={() => setDialogOpen(false)} />
            </>
        );
    }

    return (
        <div id="social-networks-root">
            <header className={styles.header}>
                <h1>{translate('pos.social_networks.dashboard.section_title')}</h1>
                <p>{translate('pos.social_networks.dashboard.section_subtitle')}</p>
            </header>

            <div className={styles.container}>
                <ModerableSourceDashboardBox />

                <PostDashboardBox />

                <Box sx={{ gridColumn: 'span 2', marginBottom: 5 }}>
                    <StatsDashboardBox />
                </Box>
            </div>
        </div>
    );
}
