import React, { useCallback, useState, useEffect } from "react";
import {
	useTranslate,
	useDataProvider,
	useGetIdentity,
	useRedirect,
} from "react-admin";
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ModerationEntry } from "./ModerationEntry";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import SectionTitle from "../../utils/SectionTitle";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "15px",
		display: "flex"
	},
	total: {
		marginBottom: "15px"
	},
	success: {
		maxWidth: "max-content"
	},
	successButton: {
		marginTop: "15px",
	}
}));

const ModerationList = () => {
	const [moderationStack, setModerationStack] = useState([]);
	const [total, setTotal] = useState(0);
	const [currentEntry, setCurrentEntry] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const { data: identity } = useGetIdentity();
	const classes = useStyles();
	const redirectTo = useRedirect();
	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const location = useLocation();

	const fetchModerationStack = useCallback(async () => {
		setModerationStack([]);
		setCurrentEntry(0);
		setIsLoading(true);

		try {
			const response = await dataProvider.getList("moderation_entries", {
				sort: { field: "created_at", order: "ASC" },
				pagination: { page: 1, perPage: 25 },
				filter: { active_entries: identity.id, ...location.state }
			});

			setTotal(response.total);
			setModerationStack(response.data);

			if (response.data.length > 0) {
				await lockModerationStack(response.data);
			}
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false)
		}
	}, [dataProvider]);

	const lockModerationStack = useCallback(async (moderationStack) => {
		try {
			await dataProvider.updateMany("moderation_entries", {
				ids: moderationStack?.map((m) => m.id),
				data: { is_locked: true, locked_by: identity?.id, locked_at: new Date() },
			});
		} catch (error) {
			console.error("Error locking moderation stack:", error);
		}
	}, [dataProvider]);

	useEffect(() => {
		if (identity) {
			fetchModerationStack(1);
		} else {
			redirectTo("/moderation_entries/lobby")
		}
	}, [identity])

	const addSelectionStatusToEntry = (score) => {
		moderationStack[currentEntry].moderable.score = score;
	}

	const addReformulationToEntry = (reformulation) => {
		moderationStack[currentEntry].moderable.reformulated_content = reformulation;
	}

	const handleUpdate = (getPreviousContent = false) => {
		if (getPreviousContent) {
			setCurrentEntry((prev) => Math.max(prev - 1, 0));
		} else {
			setTotal((prev) => prev - 1);
			if (currentEntry === (moderationStack.length - 1)) {
				fetchModerationStack(1);
			} else {
				setCurrentEntry((prev) => prev + 1);
			}
		}
	};

	if (identity == undefined || isLoading) return <><CircularProgress /></>;

	return (
		<>
			<SectionTitle title={translate("resources.moderation_entries.label.name")} />
			<div className={classes.root}>
				{total === 0 && !isLoading ?
					<div className={classes.successContainer}>
						<Alert severity="success" className={classes.success}>
							<AlertTitle>Bravo !</AlertTitle>
							{translate("resources.moderation_entries.label.moderation_completed")}
						</Alert>
						<Button className={classes.successButton} onClick={() => fetchModerationStack(1)}>
							{translate("resources.moderation_entries.label.load_more")}
						</Button>
					</div>
					:
					<ModerationEntry
						moderationEntry={moderationStack[currentEntry]}
						onUpdate={handleUpdate}
						onUpdateSelection={addSelectionStatusToEntry}
						onReformulate={addReformulationToEntry}
					/>
				}
			</div>
		</>
	);
};

export default ModerationList;
