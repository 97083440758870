import { formatActeur, formatGroupeParlementaire } from "../formatters";
import { ActeurIcon } from "@logora/parliament.icons.regular_icons";

const participantsDebatsFields = {
    'acteurRef': { label: 'Nom', field: 'acteurRef', include: "acteurRef.groupeParlementaire", format: formatActeur }
}

const participantsDebatsFilters = [
]

export const participantsDebatsData = {
    key: "interventions",
    name: "Orateurs",
    filters: participantsDebatsFilters,
    fields: participantsDebatsFields,
    distinct: 'acteurRefUid',
    searchTable: "acteurRef",
    icon: <ActeurIcon height={20} width={20} />
}