import React from 'react';
import { Route } from 'react-router-dom';
import { CustomRoutes } from 'react-admin';
import { Search } from './search';
import { ActeurShow } from './acteurs';
import { QuestionShow } from './questions';
import { OrganeShow } from './organes';
import { DocumentShow } from './documents';
import { DossierShow } from './dossiers';
import { ScrutinShow } from './scrutins';
import { ReunionShow } from './reunions';
import { AlertList } from './alerts';

export const routes = [
    <CustomRoutes>
        <Route path="/parliament/amendements/:id" element={<Search dataset={"amendements"} />} />
        <Route path="/parliament/:dataset?" element={<Search />} />
        <Route path="/parliament/alertes" element={<AlertList />} />
        <Route path="/parliament/acteurs/:id" element={<ActeurShow />} />
        <Route path="/parliament/questions/:id" element={<QuestionShow />} />
        <Route path="/parliament/organes/:id" element={<OrganeShow />} />
        <Route path="/parliament/documents/:id" element={<DocumentShow />} />
        <Route path="/parliament/dossiers/:id" element={<DossierShow />} />
        <Route path="/parliament/scrutins/:id" element={<ScrutinShow />} />
        <Route path="/parliament/reunions/:id/:interventionId?" element={<ReunionShow />} />
    </CustomRoutes>
]