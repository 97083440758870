import { Box, Stack } from '@mui/material';
import React from 'react';
import { providerIcons } from './icons';
import { OAuth2Button } from '@logora/debate.auth.oauth2_button';
import { useTranslate } from 'react-admin';
import styles from './OAuthProviderCard.module.scss';
import { OAuthProvider } from './oauth_provider';

export function OAuthProviderCard({
    provider: { name, authUrl, clientId, scope, forceAuth = false, accessType }
}: {
    provider: OAuthProvider;
}) {
    const translate = useTranslate();

    return (
        <Box
            sx={{
                padding: '20px',
                border: '1px solid var(--clr-neutral-800)',
                borderRadius: '6px'
            }}
        >
            <Stack>
                <img className={styles.platformIcon} src={providerIcons[name]} alt="" />
                <h3 className={styles.platformName}>{name}</h3>
                <p className={styles.platformDescription}>
                    {translate(`pos.social_networks.add_source.platforms.${name}.description`)}
                </p>

                <OAuth2Button
                    popup={false}
                    authDialogUrl={authUrl}
                    clientId={clientId}
                    scope={scope}
                    forceAuth={forceAuth}
                    responseType="code"
                    accessType={accessType}
                    redirectUri={process.env.REACT_APP_API_AUTH_CALLBACK}
                    className={`btn btn-outline ${styles.connectBtn}`}
                    state={`${window.location.origin}/#/social-networks/${name}/callback`}
                >
                    {translate('pos.social_networks.actions.connect')}
                </OAuth2Button>
            </Stack>
        </Box>
    );
}
