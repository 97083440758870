import React from "react";
import { 
    Button,
    useDataProvider,
    useRefresh,
    useNotify
} from "react-admin";
import { useModal, Modal } from '@logora/debate.dialog.modal';
import { Box, SnackbarContent } from '@mui/material';
import { AddAlertIcon } from "@logora/parliament.icons.regular_icons";
import styles from './AlertList.module.scss';
import AlertForm from "./AlertForm";

const AlertListEmpty = () => {
    const { showModal, hideModal } = useModal();
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const createAlert = (formData) => {
        dataProvider.create("alerts", {
            data: { ...formData }
        }).then(() => {
            hideModal();
            refresh();
            notify(
                <SnackbarContent 
                    autoHideDuration={3000} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    message={ "Alerte créée avec succès" }
                    sx={{ backgroundColor: "#2E7D32", color: "#FFF" }}
                />
            );
        }).catch(() => {
            console.log("Une erreur est survenue")
        })
    }
	return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={5}>
            <AddAlertIcon className={styles.alertIcon} width={80} height={80} />
            <Button className={styles.mainButton} label="Ajouter une alerte" onClick={() => 
                showModal(
                    <Modal style={{ maxWidth: "650px" }}>
                        <AlertForm type="create" onCreate={(data) => createAlert(data)}/>
                    </Modal>
                )
            } />
        </Box>
	);
}

export default AlertListEmpty;