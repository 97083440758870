import React from 'react';
import { Show } from '../components/show';
import { useRedirect } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { Avatar } from '@logora/debate.user.avatar';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { GroupLabel } from '@logora/parliament.parliament_group.group_label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { SenatHemicycle } from '@logora/parliament.parliament_chamber.senat_hemicycle';
import { FollowButton } from '@logora/parliament.ui.follow_button';
import { formatDateNaisToAge } from '../formatters';
import { MultiList } from '../search/MultiList';
import { questionData } from '../questions';
import { interventionData } from '../interventions';
import { documentData } from '../documents';
import { dossierData } from '../dossiers';
import { amendementData } from '../amendements';
import { ActeurDashboard } from './ActeurDashboard';
import { useCreateAlert } from '../alerts';
import cx from 'classnames';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import XIcon from '@mui/icons-material/X';
import LanguageIcon from '@mui/icons-material/Language';
import styles from './ActeurShow.module.scss';
import cloneDeep from 'lodash/cloneDeep';

export const ActeurDetails = ({ resource }) => {
    const redirectTo = useRedirect();
    const createAlert = useCreateAlert({ acteurRefUid: resource.uid });
    const adresses = [...resource.adressesElectroniques, ...resource.adressesPostales];

    const getDatasets = () => {
        const dossiersFilters = dossierData.filters.concat([
            { field: 'acteurPrincipalRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const dossiersDataset = Object.assign(cloneDeep(dossierData), { filters: dossiersFilters });

        const questionsFilters = questionData.filters.concat([
            { field: 'acteurRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const questionsDataset = Object.assign(cloneDeep(questionData), { filters: questionsFilters });

        const interventionsFilters = interventionData.filters.concat([
            { field: 'acteurRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const interventionsDataset = Object.assign(cloneDeep(interventionData), { filters: interventionsFilters });

        const documentsFilters = documentData.filters.concat([
            { field: 'auteurPrincipalUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const documentsDataset = Object.assign(cloneDeep(documentData), { filters: documentsFilters });

        const amendementsFilters = amendementData.filters.concat([
            { field: 'acteurRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const amendementsDataset = Object.assign(cloneDeep(amendementData), { filters: amendementsFilters });

        return {
            dossiers: dossiersDataset,
            amendements: amendementsDataset,
            documents: documentsDataset,
            questions: questionsDataset,
            interventions: interventionsDataset
        };
    };

    const displayContacts = () => {
        const phone = adresses.find((adresse) => adresse.typeLibelle === 'Téléphone');
        const email = adresses.find((adresse) => adresse.typeLibelle === 'Mèl');
        const publishedAdressePostale = adresses.find(
            (adresse) => adresse.typeLibelle === 'Adresse publiée de circonscription'
        );
        const defaultAdressePostale = adresses.find((adresse) => adresse.typeLibelle === 'Adresse officielle');
        const adresseToDisplay = publishedAdressePostale || defaultAdressePostale;

        const adressePostale = adresseToDisplay
            ? `${adresseToDisplay.numeroRue || ''} ${adresseToDisplay.nomRue || ''} ${adresseToDisplay.codePostal || ''
            } ${adresseToDisplay.ville || ''}`
            : 'Adresse non disponible';

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <span className={styles.contactLine}>
                    <PhoneIcon sx={{ marginRight: '0.5em' }} /> {phone ? phone.valElec : 'Non disponible'}
                </span>
                <span className={styles.contactLine}>
                    <HomeIcon sx={{ marginRight: '0.5em' }} /> {adressePostale}
                </span>
                <span className={styles.contactLine}>
                    <EmailIcon sx={{ marginRight: '0.5em' }} /> {email ? email.valElec : 'Non disponible'}
                </span>
            </Box>
        );
    };

    const displayWebAddresses = () => {
        const website = adresses.find((adresse) => adresse.typeLibelle === 'Site internet')
            ? 'https://' + adresses.filter((a) => a.typeLibelle === 'Site internet')[0]?.valElec
            : undefined;
        const twitter = resource.compteTwitter ? `https://x.com/${resource.compteTwitter?.substring(1)}` : undefined;

        return (
            <>
                {twitter && (
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cx(styles.button, styles.buttonRounded)}
                        href={twitter}
                    >
                        <XIcon />
                    </a>
                )}
                {website && (
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cx(styles.button, styles.buttonRounded)}
                        href={website}
                    >
                        <LanguageIcon />
                    </a>
                )}
            </>
        );
    };

    const displayActifMandat = () => {
        const { mandatPrincipal, nombreMandats } = resource;
        const cardinal = nombreMandats > 1 ? `${nombreMandats}ème mandat` : `${nombreMandats}er mandat`;

        if (resource.actif) {
            return (
                <Typography variant="body2" sx={{ color: '#777777' }}>
                    Mandat en cours depuis le{' '}
                    {new Date(mandatPrincipal.dateDebut)?.toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    }) ?? ''}{' '}
                    ({cardinal})
                </Typography>
            );
        }

        return (
            <Typography variant="body2" sx={{ color: '#777777' }}>
                Pas de mandat en cours
            </Typography>
        );
    };

    return (
        <div className={styles.mainContainer}>
            <Box className={styles.avatarBackground} display={'flex'} gap={'2em'} sx={{ marginTop: '1em' }}>
                <Avatar avatarUrl={resource.urlImage} size={124} />
                <Box display={'column'}>
                    <Typography sx={{ fontSize: '34px' }}>
                        {resource.prenom} {resource.nom}
                    </Typography>
                    <Typography
                        className={styles.link}
                        variant="body2"
                        sx={{ color: '#777777' }}
                        onClick={() => redirectTo(`/parliament/organes/${resource.circonscriptionUid}`)}
                    >
                        {resource.circonscription?.libelle}
                    </Typography>
                    <Typography variant="body2" className={styles.nombreMandats}>
                        {displayActifMandat()}
                    </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginLeft={'auto'} gap="0.5em">
                    {displayWebAddresses()}
                    <FollowButton onFollow={createAlert} />
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                alignItems="start"
                gap="0.5em"
                sx={{ backgroundColor: '#f9f9f9', padding: '15px' }}
            >
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1em">
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Groupe politique">
                            {resource.groupeParlementaire ?
                                <div
                                    onClick={() => redirectTo(`/parliament/organes/${resource.groupeParlementaireUid}`)}
                                >
                                    <GroupLabel
                                        groupName={resource.groupeParlementaire?.libelle}
                                        color={resource.groupeParlementaire?.couleurAssociee}
                                        className={styles.linkHover}
                                        onClick={() =>
                                            redirectTo(`/parliament/organes/${resource.groupeParlementaireUid}`)
                                        }
                                        size="small"
                                    />
                                </div>
                                :
                                <i>Pas de groupe politique actuellement</i>
                            }
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Âge">
                            <div>{formatDateNaisToAge('dateNais', resource)} ans</div>
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Commission">
                            {resource.commissionPermanente ?
                                <div
                                    className={styles.link}
                                    onClick={() => redirectTo(`/parliament/organes/${resource.commissionPermanenteRefUid}`)}
                                >
                                    {resource.commissionPermanente?.libelle}
                                </div>
                                :
                                <i>Pas de commission actuellement</i>
                            }
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Profession">
                            {resource.profession ?
                                <div>{resource.profession}</div>
                                :
                                <i>Profession non disponible</i>
                            }
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Collaborateurs">
                            <Box display="flex" flexDirection="column">
                                {resource.collaborateurs?.length ?
                                    resource.collaborateurs?.map((collab) => {
                                        return (
                                            <MemberBox
                                                firstName={collab.prenom}
                                                lastName={collab.nom}
                                                key={collab.id}
                                                size="small"
                                                layout="oneLine"
                                            />
                                        );
                                    })
                                    :
                                    <i>Pas de collaborateurs</i>
                                }
                            </Box>
                        </UiBox>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <UiBox className={styles.uiBox} title="Contacts">
                            {displayContacts()}
                        </UiBox>
                    </Box>
                </Box>
                <Box flex={2} className={styles.svgContainer}>
                    {resource.chambre === 'AN' && (
                        <AssembleeHemicycle
                            className={styles.svgIcon}
                            highlightedSeats={[
                                {
                                    id: parseInt(resource.mandatPrincipal?.placeHemicycle),
                                    color: resource.groupeParlementaire?.couleurAssociee,
                                    label: (
                                        <div style={{ padding: '2px 6px' }}>
                                            Siège {resource.mandatPrincipal?.placeHemicycle}
                                        </div>
                                    ),
                                    hoverable: false,
                                    focusable: false
                                }
                            ]}
                        />
                    )}

                    {resource.chambre === 'SN' && (
                        <SenatHemicycle
                            className={styles.svgIcon}
                            highlightedSeats={[
                                {
                                    id: parseInt(resource.placeHemicycle),
                                    color: resource.groupeParlementaire?.couleurAssociee,
                                    label: <div style={{ padding: '2px 6px' }}>Siège {resource.placeHemicycle}</div>,
                                    hoverable: false,
                                    focusable: false
                                }
                            ]}
                        />
                    )}
                </Box>
            </Box>
            <Box sx={{ width: '100%', marginTop: '1em' }}>
                <ActeurDashboard acteur={resource} />
            </Box>
            <Box sx={{ width: '100%', marginTop: '1em' }}>
                <MultiList datasets={getDatasets()} showListSearch={true} variant={'tab'} />
            </Box>
        </div>
    );
};

export const ActeurShow = () => {
    return (
        <Show
            resource={'acteurs'}
            includes={[
                'groupeParlementaire',
                'mandatPrincipal',
                'collaborateurs',
                'circonscription',
                'commissionPermanente',
                'adressesElectroniques',
                'adressesPostales'
            ]}
            backLink={'/parliament/acteurs'}
            backText={'Voir tous les acteurs'}
        >
            <ActeurDetails />
        </Show>
    );
};
