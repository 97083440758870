import React, { useState, useEffect } from 'react';
import { FormatAlignLeftOutlined, RemoveRedEye } from '@mui/icons-material';
import { ALLOWED_CODE_ACTES } from '../actesDefinitions';
import styles from './Agenda.module.scss';

export const Agenda = ({ actesLegislatifs = [], selectedActe }) => {
  const [pointsOdj, setPointsOdj] = useState([])

  useEffect(() => {
    setPointsOdj([])
    if (!selectedActe) return

    const currentActeDefinition = ALLOWED_CODE_ACTES[selectedActe.codeActe]
    if (!currentActeDefinition?.codeReunions) return

    const currentPointsOdj = actesLegislatifs
      .filter((a) => currentActeDefinition.codeReunions.find((codeReunion) => a.codeActe.startsWith(codeReunion)))
      .map(a => a.pointOdj)
      .filter(b => b)

    setPointsOdj(currentPointsOdj)
  }, [actesLegislatifs, selectedActe])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Agenda et compte-rendus
        </div>
      </div>
      <div className={styles.content}>
        {pointsOdj?.length > 0 ?
          <>
            {
              pointsOdj.map(pointOdj => (
                <div key={pointOdj.uid} className={styles.item} onClick={() => window.open(`#/parliament/reunions/${pointOdj.agendaRef?.uid}`)}>
                  <div className={styles.itemInfo}>
                    <div className={styles.date}>{new Date(pointOdj.agendaRef?.timestampDebut).toLocaleDateString("fr-FR", {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit'
                    })}</div>
                    <div className={styles.itemObjet}>{pointOdj.objet}</div>
                  </div>
                  <div className={styles.buttonsContainer}>
                    {pointOdj.agendaRef?.compteRenduDisponible &&
                      <div
                        variant="outline"
                        className={styles.button}
                      >
                        <FormatAlignLeftOutlined />
                        <span>CR</span>
                      </div>
                    }

                    {pointOdj.videoUrl && <a href={pointOdj.videoUrl} className={styles.buttonLink} aria-label="Voir la retranscription vidéo">
                      <button
                        variant="outline"
                        className={styles.button}
                        aria-label="Voir la vidéo"
                      >
                        <RemoveRedEye />
                        Vidéo
                      </button>
                    </a>}
                  </div>
                </div>
              ))
            }
          </>
          :
          <i className={styles.empty}>Pas de réunions</i>
        }
      </div>
    </div>
  )
}
