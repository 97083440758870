import React, { useState, useEffect } from 'react';
import {
    Switch,
    FormControlLabel,
    Button,
    Box,
} from '@mui/material';
import { customCreatableSelectStyles } from './customSelectStyles';
import { Select } from '@logora/debate.input.select';
import { TextInput } from '@logora/debate.input.text_input';
import { useModal } from '@logora/debate.dialog.modal';
import { DocumentIcon } from '@logora/parliament.icons.regular_icons';
import { AmendmentIcon } from "@logora/parliament.icons.regular_icons";
import { DossierIcon } from '@logora/parliament.icons.regular_icons';
import { SpeechIcon } from "@logora/parliament.icons.regular_icons";
import { OrganeIcon } from "@logora/parliament.icons.regular_icons";
import { QuestionIcon } from '@logora/parliament.icons.regular_icons';
import { ReunionIcon } from "@logora/parliament.icons.regular_icons";
import { VoteIcon } from "@logora/parliament.icons.regular_icons";
import { ActeurIcon } from "@logora/parliament.icons.regular_icons";
import CreatableSelect from "react-select/creatable";
import styles from './AlertForm.module.scss';

const AlertForm = ({ onCreate, onUpdate, onDelete, record }) => {
    const { hideModal } = useModal();
    const [formData, setFormData] = useState({
        name: '',
        keywords: [],
        active: true,
        searchQuery: false,
        sources: [],
        frequency: 'daily',
        receiveByEmail: false,
        receiveByNotification: false
    });

    useEffect(() => {
        if (record) {
            setFormData((prevData) => {
                return {
                    name: record.name,
                    keywords: record.keywords?.map(k => ({ label: k.name, value: k.name })),
                    active: record.active,
                    searchQuery: record.searchQuery,
                    sources: record.sources?.map(s => s.name),
                    frequency: record.frequency,
                    receiveByEmail: record.email_notifications,
                    receiveByNotification: record.push_notifications

                }
            })
        }
    }, [record]);

    const getIcon = (name) => {
        const icons = {
            documents: <DocumentIcon width={18} height={18} />,
            amendements: <AmendmentIcon width={18} height={18} />,
            dossiers: <DossierIcon width={18} height={18} />,
            scrutins: <VoteIcon width={18} height={18} />,
            interventions: <SpeechIcon width={18} height={18} />,
            organes: <OrganeIcon width={18} height={18} />,
            questions: <QuestionIcon width={18} height={18} />,
            reunions: <ReunionIcon width={18} height={18} />,
            votes: <VoteIcon width={18} height={18} />,
            acteurs: <ActeurIcon width={18} height={18} />
        };
        return icons[name.toLowerCase()] || null;
    };

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [type === 'checkbox' ? name : name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSourceToggle = (sourceValue) => {
        setFormData((prevData) => {
            const isSelected = prevData.sources.includes(sourceValue);
            return {
                ...prevData,
                sources: isSelected
                    ? prevData.sources.filter(value => value !== sourceValue)
                    : [...prevData.sources, sourceValue]
            };
        });
    };

    const handleFrequencyChange = (option) => {
        setFormData((prevData) => ({
            ...prevData,
            frequency: option.value
        }));
    };

    const handleKeywordsChange = (selectedOptions) => {
        const cleanedValues = selectedOptions
            ? selectedOptions.map(option => ({ label: option.value.trim().toLowerCase(), value: option.value.trim().toLowerCase() }))
            : [];
        setFormData((prevData) => ({
            ...prevData,
            keywords: cleanedValues
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (record && onUpdate) {
            onUpdate({
                id: record.id, 
                name: formData.name,
                keywords: formData.keywords.map((k) => k.value),
                active: formData.active,
                searchQuery: formData.searchQuery,
                sources: formData.sources,
                frequency: formData.frequency,
                email_notifications: formData.receiveByEmail,
                push_notifications: formData.receiveByNotification
            })
        } else if (onCreate) {
            onCreate({
                name: formData.name,
                keywords: formData.keywords.map((k) => k.value),
                active: formData.active,
                searchQuery: formData.searchQuery,
                sources: formData.sources,
                frequency: formData.frequency,
                email_notifications: formData.receiveByEmail,
                push_notifications: formData.receiveByNotification
            })
        }
    };

    const handleDelete = () => {
        if (!record) { hideModal() }
        if (onDelete) {
            onDelete(record.id);
        }
    }

    const sourceOptions = [
        { name: "dossiers", value: "dossiers", text: "Dossiers" },
        { name: "acteurs", value: "acteurs", text: "Acteurs" },
        { name: "amendements", value: "amendements", text: "Amendements" },
        { name: "scrutins", value: "scrutins", text: "Scrutins" },
        { name: "reunions", value: "reunions", text: "Réunions" },
        { name: "interventions", value: "interventions", text: "Interventions" },
        { name: "questions", value: "questions", text: "Questions" },
        { name: "documents", value: "documents", text: "Documents" },
        { name: "organes", value: "organes", text: "Organes" }
    ];

    const frequencyOptions = [
        { name: "instant", value: "instant", text: "Instantanée" },
        { name: "daily", value: "daily", text: "Quotidienne" },
        { name: "weekly", value: "weekly", text: "Hebdomadaire" }
    ];

    return (
        <form className={styles.formContainer}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <h1 className={styles.formTitle}>{ record ? "Modifier l'alerte" : "Nouvelle alerte" }</h1>
                <FormControlLabel
                    label="Alerte active"
                    control={
                        <Switch
                            checked={formData.active}
                            onChange={handleChange}
                            name="active"
                            className={styles.switchLabel}
                        />
                    }
                />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"1em"} mb={"20px"}>
                <Box display={"flex"} flexDirection={"column"}>
                    <div className={styles.alertOptions}>Nom de l'alerte</div>
                    <TextInput
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={styles.textInput}
                        inputClass={styles.textInputClass}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                    <div className={styles.alertOptions}>Mots-clés</div>
                    <CreatableSelect
                        styles={customCreatableSelectStyles}
                        components={{ DropdownIndicator: null }}
                        isMulti
                        value={formData.keywords}
                        onChange={handleKeywordsChange}
                        placeholder='Ajouter des mots-clés...'
                    />
                </Box>
            </Box>
            <div className={styles.selectWrapper}>
                <div className={styles.alertOptions}>Sources</div>
                <Box className={styles.sourceButtons}>
                    {sourceOptions.map(option => (
                        <div
                            key={option.value}
                            onClick={() => handleSourceToggle(option.value)}
                            className={`${styles.sourceButton} ${formData.sources.includes(option.value) ? styles.sourceButtonSelected : ''}`}
                        >
                            {getIcon(option.text)} {option.text}
                        </div>
                    ))}
                </Box>
            </div>
            <div className={styles.selectWrapper}>
                <div className={styles.alertOptions}>Fréquence</div>
                <Select
                    label="Fréquence"
                    className={styles.selectInput}
                    options={frequencyOptions}
                    defaultOption={formData.frequency}
                    onChange={handleFrequencyChange}
                />
            </div>
            <div className={styles.alertOptions}>Réception des alertes</div>
            <Box display={"flex"} flexDirection={"column"}>
                <FormControlLabel
                    label="Par mail"
                    control={
                        <Switch
                            checked={formData.receiveByEmail}
                            onChange={handleChange}
                            name="receiveByEmail"
                            className={styles.switchLabel}
                        />
                    }
                />
                {/* <FormControlLabel
                    label="Notifications"
                    control={
                        <Switch
                            checked={formData.receiveByNotification}
                            onChange={handleChange}
                            name="receiveByNotification"
                            className={styles.switchLabel}
                        />
                    }
                /> */}
            </Box>
            <div className={styles.buttons}>
                {record && onDelete &&
                    <Button variant="outlined" type="button" className={styles.deleteButton} onClick={handleDelete}>
                        SUPPRIMER L'ALERTE
                    </Button>
                }
                <Button variant="outlined" type="button" className={styles.cancelButton} onClick={hideModal}>
                    ANNULER
                </Button>
                <Button variant="outlined" type="button" className={styles.submitButton} onClick={handleSubmit}>
                    VALIDER
                </Button>
            </div>
        </form>
    );
};

export default AlertForm;