import React from 'react';
import { AmendmentBox } from '@logora/parliament.amendment.amendment_box'
import { List } from '../../../parliament/search/List';
import { amendementData } from '../../amendements';
import styles from './AmendementList.module.scss';
import cloneDeep from 'lodash/cloneDeep';

export const AmendementList = ({ document, subdivisionRefHash = null }) => {
  const getAmendementDataset = () => {
    const articles = document?.subdivisions.filter(s => s.type === "Article").sort((a, b) => a.sequence - b.sequence)
    const articlesOptions = articles.map(a => { return { value: a.hash, label: a.titre } } )
    const excludedFilters = ["chambre", "numeroLong", "dateDepot.gte", "dateDepot.lte"]
    
    amendementData.filters = amendementData.filters.filter((f) => excludedFilters.includes(f))
    const amendementsFilters = amendementData.filters.concat([
      { field: "texteLegislatifRefUid", hidden: true, defaultValue: document?.uid, type: 'text' },
      {
        field: "subdivisionRefHash", label: "Article", defaultValue: subdivisionRefHash, type: 'select', options: [
          { value: null, label: 'Tous' },
          ...(articlesOptions)
        ]
      },
    ])

    return Object.assign(cloneDeep(amendementData), { filters: amendementsFilters })
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Amendements
        </div>
      </div>
      <div className={styles.contentContainer}>
        {document?.uid &&
          <List
            dataset={getAmendementDataset()}
            globalFilters={[]}
            display={true}
            key={"amendements"}
            role="tabpanel"
            tabIndex={0}
            showSearch={true}
            Children={AmendmentBox}
          />
        }
      </div>
    </div>
  );
};
