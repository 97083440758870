import React from 'react';
import styles from './Timeline.module.scss';
import { TimelineMilestone } from './TimelineMilestone';

export const Timeline = ({ milestones = [], selectedActeUid, currentActeUid, onChangeActe }) => {
  const legislatures = milestones.reduce((acc, milestone) => {
    (acc[milestone.acteLegislatif?.legislature ? milestone.acteLegislatif?.legislature : "17"] = acc[milestone.acteLegislatif?.legislature ? milestone.acteLegislatif?.legislature : "17"] || []).push(milestone);
    return acc;
  }, {});

  return (
    <div className={styles.timelineContainer}>
      <div className={styles.timeline}>
        {Object.keys(legislatures).map((legislature) => (
          <>
            <div key={legislature} className={styles.legislatureContainer}>
              { legislature?.length && <div className={styles.legislatureBanner}>{legislature}ème législature</div>}
              {legislatures[legislature].map((milestone, index) => (
                <TimelineMilestone
                  key={index}
                  milestone={milestone}
                  selectedActeUid={selectedActeUid}
                  currentActeUid={currentActeUid}
                  onChangeActe={onChangeActe}
                />
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};