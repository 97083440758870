// in src/MyMenu.js
import React from 'react';
import { Menu, useTranslate } from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';

export const ParliamentMenu = () => {
	const translate = useTranslate();
	
	return (
		<>
			<Menu.Item
				to="/parliament/dossiers" 
				primaryText={translate(`resources.parliament.search`)}
				leftIcon={<SearchIcon />}
			/>
			<Menu.Item
				to="/parliament/alertes"
				primaryText={translate(`resources.parliament.alerts`)}
				leftIcon={<NotificationsIcon />}
			/>
		</>
	);
};