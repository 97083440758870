import React, { useState, useEffect, useCallback } from 'react'
import { useAuthenticated, useDataProvider } from 'react-admin';
import { SearchBar } from '../search/SearchBar';
import { BillBox } from '@logora/parliament.bill.bill_box';
import styles from "./Dashboard.module.scss";

export const Dashboard = () => {
    const [dossiersLoading, setDossiersLoading] = useState(true);
    const [lastDossiers, setLastDossiers] = useState([]);
    const dataProvider = useDataProvider();
    useAuthenticated()

    useEffect(() => {  
        fetchLastDossiers();
    }, []);

    const fetchLastDossiers = useCallback(async () => {
        try {
            const response = await dataProvider.getList('dossiers', {
                filter: {},
                include: ['acteurPrincipalRef.groupeParlementaire'],
                sort: { field: 'dateDepot', order: 'desc' },
                pagination: { page: 1, perPage: 3 }
            });
            const lastDossiers = response.data;
            if (lastDossiers) {
                setLastDossiers(lastDossiers);
            }
            setDossiersLoading(false)
        } catch (error) {
            console.log(error)
            setDossiersLoading(false)
        }
    }, [dataProvider]);
    

    return (
        <div className={styles.dashboard}>
            <div className={styles.searchContainer}>
                <SearchBar
                    onSearchChange={() => null}
                    onUpdateGlobalFilters={() => null}
                />
            </div>
            <div className={styles.dossiersContainer}>
                <div className={styles.title}>Derniers dossiers</div>
                <div className={styles.dossiersWrapper}>
                    { dossiersLoading ? <div>Loading...</div> : lastDossiers.map((dossier) => ( <BillBox resource={dossier} /> )) }
                </div>
            </div>
            <div className={styles.votesContainer}>
                <div className={styles.title}>Derniers votes</div>
            </div>
            <div className={styles.agendaContainer}>
                <div className={styles.title}>Agenda</div>
            </div>
        </div>
    )
}