import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { ExposeMotifs } from './ExposeMotifs';
import TexteBox from './TexteBox';
import { Agenda } from './Agenda';
import { AmendementList } from './AmendementList';
import styles from './ActeLegislatifDetails.module.scss';

export const ActeLegislatifDetails = ({ acteLegislatif, acteDefinition, actesLegislatifs, exposeMotifsTexte }) => {
  const dataProvider = useDataProvider()
  const [subdivisionRefHash, setSubdivisionRefHash] = useState("")
  const [document, setDocument] = useState(null)

  const handleAmendementButtonClick = (subdivision) => {
    setSubdivisionRefHash(subdivision.hash);
  };

  const fetchDocument = useCallback(async (uid) => {
    try {
      const { data } = await dataProvider.getOne('documents', {
        id: uid,
        include: ['alineas', 'subdivisions'],
      });

      setDocument(data);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  }, [dataProvider]);

  useEffect(() => {
    if (acteLegislatif?.documentRefUid) {
      fetchDocument(acteLegislatif?.documentRefUid)
    } else {
      setDocument(null)
    }
  }, [acteLegislatif])

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.top}>
          <ExposeMotifs document={document} text={exposeMotifsTexte} />
          {acteDefinition?.showAgenda !== false &&
            <Agenda actesLegislatifs={actesLegislatifs} selectedActe={acteLegislatif} />
          }
        </div>

        <div className={styles.bottom}>
          <TexteBox document={document} onAmendementButtonClick={handleAmendementButtonClick} />
          {acteDefinition?.showAmendements !== false &&
            <AmendementList
              key={subdivisionRefHash}
              document={document}
              subdivisionRefHash={subdivisionRefHash}
            />
          }
        </div>
      </div>
    </div>
  );
};
