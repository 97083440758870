import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import httpClient from '../httpClient';
import { CircularProgress, Stack } from '@mui/material';
import { providerIcons } from './oauth_providers/icons';
import { ModerableSource } from './moderable_sources/moderableSource';
import { useNotify, useTranslate } from 'react-admin';
import { Cancel, CheckCircle, KeyboardBackspace } from '@mui/icons-material';

export const AuthPage = () => {
    const { provider } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const error = searchParams.get('error');
    const navigate = useNavigate();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [err, setErr] = useState(false);
    const translate = useTranslate();

    const getContent = () => {
        if (loading) {
            return (
                <>
                    <CircularProgress />
                    <p>Connecting to {provider}...</p>
                </>
            );
        }

        if (success) {
            return (
                <>
                    <CheckCircle color="success" sx={{ fontSize: '3rem' }} />
                    <p>{translate('pos.social_networks.connection.success', { provider })}</p>
                </>
            );
        }

        if (err) {
            return (
                <>
                    <Cancel color="error" sx={{ fontSize: '3rem' }} />
                    <p>{translate('pos.social_networks.connection.error', { provider })}</p>
                </>
            );
        }

        return null;
    };

    useEffect(() => {
        setLoading(true);

        if (!provider || !code || error) {
            setLoading(false);
            notify(error ? error : translate('pos.social_networks.connection.error', { provider }), { type: 'error' });
            navigate('/');
            return;
        }

        const url = new URL(
            `/social_moderator/moderable_sources/${provider}`,
            process.env.REACT_APP_SOCIAL_MODERATOR_URL as string
        );

        url.searchParams.append('code', code);

        httpClient(url.toString(), { method: 'POST' })
            .then((res) => {
                if (res && res.status === 200) {
                    setSuccess(true);
                    notify(translate('pos.social_networks.connection.error', { provider }), { type: 'success' });
                } else {
                    setErr(true);
                    notify(translate('pos.social_networks.connection.error', { provider }), { type: 'error' });
                }
            })
            .finally(() => setLoading(false));
    }, []);

    if (!provider) return null;

    return (
        <Stack direction="column" alignItems="center" justifyContent="center" minHeight="100vh" gap={5}>
            <img src={providerIcons[provider as ModerableSource['provider']]} />
            {getContent()}
            {!loading && (
                <a href="/" className="btn btn-primary">
                    <KeyboardBackspace />
                    {translate('pos.social_networks.back_to_home')}
                </a>
            )}
        </Stack>
    );
};
