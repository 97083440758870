import React from "react";
import { Filter, SearchInput, TextInput, DateInput, SelectInput, useTranslate } from "react-admin";
import { useSettingsContext } from "../utils/SettingsProvider";
import LanguageSelectInput from "../inputs/LanguageSelectInput";

const UserFilter = (props) => {
	const translate = useTranslate();
	const settingsContext = useSettingsContext();
	return (
		<Filter {...props}>
			<SearchInput source='query' alwaysOn variant={"outlined"} />
			<DateInput source='created_at_gte' alwaysOn label={translate("resources.filters.from_date")} variant={"outlined"} />
			<DateInput source='created_at_lte' alwaysOn label={translate("resources.filters.to_date")} variant={"outlined"} />
			<TextInput source='sub_application_name' label={translate("resources.filters.sub_application_name")} variant={"outlined"} />
			{settingsContext?.settings?.translation?.enable === true &&
				<LanguageSelectInput
					resettable
					alwaysOn
					style={{ marginBottom: "0" }}
				/>
			}
			<TextInput source='uid' alwaysOn variant={"outlined"} />
			<TextInput source='hash_id' alwaysOn variant={"outlined"} />
			<SelectInput
				sx={{ marginBottom: "0" }}
				variant={"outlined"}
				helperText={false}
				label={translate("resources.users.fields.role")}
				source='role'
				alwaysOn
				resettable
				choices={[
					{ id: '0', name: 'resources.users.fields.role_default' },
					{ id: '1', name: 'resources.users.fields.role_editor' },
					settingsContext?.settings?.actions?.moderatorRole === true && { id: '2', name: 'resources.users.fields.role_moderator' },
				]}
        	/>
		</Filter>
	);
};

export default UserFilter;
