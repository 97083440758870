import React from 'react';
import { DashboardBox } from '../dashboard/DashboardBox';
import { Leaderboard } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import styles from './StatsDashboardBox.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { useGetList } from 'ra-core';
import { AcceptedContentMetricDashboardBox } from './AcceptedContentMetricDashboardBox';
import { PostMetricDashboardBox } from './PostMetricDashboardBox';
import { LikeMetricDashboardBox } from './LikeMetricDashboardBox';
import { ModerableContentTimelineDashboardBox } from './ModerableContentTimelineDashboardBox';
import { ModerableSource } from '../moderable_sources/moderableSource';
import { CommentMetricDashboardBox } from './CommentMetricDashboardBox';
import { useTranslate } from 'react-admin';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerProps, DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocale } from 'react-admin';

function DatePicker({ className, ...props }: DatePickerProps<Dayjs> & { locale?: string }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={props.locale || 'en'}>
            <MuiDatePicker
                {...props}
                className={styles.dateInput}
                slotProps={{
                    textField: {
                        sx: {
                            border: '1px solid var(--clr-neutral-800)',
                            outline: 'none',
                            borderRadius: '6px',
                            width: '13rem'
                        }
                    }
                }}
            />
        </LocalizationProvider>
    );
}

export function StatsDashboardBox() {
    const translate = useTranslate();
    const locale = useLocale();
    dayjs.locale(locale);

    const [dateRange, setDateRange] = React.useState<[Dayjs, Dayjs]>([dayjs().subtract(1, 'month'), dayjs()]);

    const [activeProviders, setActiveProviders] = React.useState<string[]>([]);
    const { data: sources } = useGetList<ModerableSource>(
        'moderable_sources',
        {},
        {
            staleTime: Infinity,
            onSuccess: (data) => {
                setActiveProviders([...new Set(sources?.map((s) => s.provider))]);
            }
        }
    );
    const providers = [...new Set(sources?.map((s) => s.provider))];

    const handleToggleActive = (provider: string) => {
        if (activeProviders.includes(provider)) {
            if (activeProviders.length > 1) {
                setActiveProviders(activeProviders.filter((p) => p !== provider));
            }
        } else {
            setActiveProviders([...activeProviders, provider]);
        }
    };

    return (
        <DashboardBox title={translate('pos.social_networks.dashboard.statistics')} icon={Leaderboard}>
            <Stack direction="row" alignItems="center" gap={2}>
                <DatePicker
                    value={dateRange[0]}
                    onChange={(date) => {
                        if (date) setDateRange([date.locale(locale), dateRange[1]]);
                    }}
                    locale={locale}
                    maxDate={dateRange[1].subtract(1, 'day')}
                />
                -
                <DatePicker
                    value={dateRange[1]}
                    onChange={(date) => {
                        if (date) setDateRange([dateRange[0], date.locale(locale)]);
                    }}
                    locale={locale}
                    minDate={dateRange[0].add(1, 'day')}
                    maxDate={dayjs()}
                />
            </Stack>

            <Stack direction="row" gap={1} marginTop={4}>
                {providers?.map((provider) => (
                    <ProviderToggle
                        key={provider}
                        provider={provider}
                        active={activeProviders.includes(provider)}
                        toggleActive={() => handleToggleActive(provider)}
                    />
                ))}
            </Stack>

            <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
                gap={2}
                marginTop={4}
                marginBottom={2}
            >
                <CommentMetricDashboardBox
                    providers={activeProviders}
                    fromDate={dateRange[0].format('YYYY-MM-DD')}
                    toDate={dateRange[1].format('YYYY-MM-DD')}
                />

                <AcceptedContentMetricDashboardBox
                    providers={activeProviders}
                    fromDate={dateRange[0].format('YYYY-MM-DD')}
                    toDate={dateRange[1].format('YYYY-MM-DD')}
                />
                <PostMetricDashboardBox
                    providers={activeProviders}
                    fromDate={dateRange[0].format('YYYY-MM-DD')}
                    toDate={dateRange[1].format('YYYY-MM-DD')}
                />
                <LikeMetricDashboardBox />
            </Box>

            <ModerableContentTimelineDashboardBox
                providers={activeProviders}
                fromDate={dateRange[0].format('YYYY-MM-DD')}
                toDate={dateRange[1].format('YYYY-MM-DD')}
            />
        </DashboardBox>
    );
}

function ProviderToggle({
    provider,
    active = false,
    toggleActive = () => {}
}: {
    provider: string;
    active?: boolean;
    toggleActive?: (provider: string) => void;
}) {
    return (
        <button className={styles.providerToggle} data-active={active} onClick={() => toggleActive(provider)}>
            {provider}
        </button>
    );
}
