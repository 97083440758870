import { ActeurIcon } from "@logora/parliament.icons.regular_icons";
import { formatActeur, formatCapitalize } from "../formatters";

const formatCirconscription = (field, item) => {
    return item.acteurRef?.circonscription?.libelle
}

const formatCommission = (field, item) => {
    return item.acteurRef?.commissionPermanente?.libelle
}

const mandatsFields = {
    acteur: { label: 'Nom', field: 'acteurRef', format: formatActeur },
    codeQualite: { label: "Qualité", field: "codeQualite", sort: "codeQualite", format: formatCapitalize },
    'circonscription': { label: "Cirsconscription", include: 'acteurRef.circonscription', field: "acteurRef.circonscription",format: formatCirconscription },
    'commissionPermanente': { label: "Commission permanente",  include: 'acteurRef.commissionPermanente', field: "acteurRef.commissionPermanente", format: formatCommission }
};

const mandatsFilters = [
    { field: "uid", label: 'UID', type: 'text' },
    {
        field: "actif", label: "Actif", type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: true, label: 'Actif' },
            { value: false, label: 'Non actif' }
        ]
    },
    { field: "nominPrincipale", label: "Nomination principale", defaultValue: true, hidden: true, type: 'boolean' }
];

export const mandatData = {
    key: "mandats",
    name: "Mandats",
    filters: mandatsFilters,
    fields: mandatsFields,
    searchTable: "acteurRef",
    icon: <ActeurIcon height={20} width={20} />,
    rowRedirect: (item) => `/parliament/acteurs/${item.acteurRefUid}`
}