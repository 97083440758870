import React from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    MenuItem
} from '@mui/material';
import { useSearchParams } from 'react-router-dom'
import styles from './FilterBar.module.scss'

export const FilterBar = ({ filters, onFilterChange, title = null }) => {
    const [_, setSearchParams] = useSearchParams();
    
    const handleFilterChange = (filter, newValue) => {
        onFilterChange(
            filter,
            filter.format ? filter.format(newValue) : newValue
        );
        setSearchParams((params) => {
            newValue !== null
                ? params.set(filter.field, newValue)
                : params.delete(filter.field);

            return params;
        });
    };

    const renderFilterField = (filter) => {
        switch (filter.type) {
            case 'date':
                return (
                    <TextField
                        type="date"
                        label={filter.label}
                        defaultValue={filter.defaultValue}
                        onChange={(e) =>
                            handleFilterChange(
                                filter,
                                e.target.value === ''
                                    ? null
                                    : e.target.value
                            )
                        }
                        size="small"
                        InputLabelProps={{ shrink: true }} 
                        sx={{ zIndex: 0 }}
                    />
                );
            case 'boolean':
                return (
                    <FormControlLabel
                        sx={{ zIndex: 0 }}
                        control={
                            <Checkbox
                                defaultChecked={filter.defaultValue}
                                onChange={(e) =>
                                    handleFilterChange(filter, e.target.checked)
                                }
                            />
                        }
                        label={filter.label}
                    />
                );
            case 'select':
                return (
                    <TextField
                        label={filter.label}
                        value={filter.value}
                        defaultValue={filter.defaultValue}
                        select
                        SelectProps={{ autoWidth: true }}
                        onChange={(e) =>
                            handleFilterChange(filter, e.target.value)
                        }
                        size="small"
                        sx={{ minWidth: '10em', zIndex: 0 }}
                    >
                        {filter.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case 'text':
                return (
                    <TextField
                        sx={{ zIndex: 0 }}
                        label={filter.label}
                        defaultValue={filter.defaultValue}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleFilterChange(
                                    filter,
                                    e.target.value === ''
                                        ? null
                                        : e.target.value
                                );
                            }
                        }}
                        size="small"
                    />
                );
        }
    };

    const visibleFilters = filters?.filter((f) => f.hidden !== true);

    return (
        <>
            {visibleFilters?.length > 0 && (
                <div className={styles.container}>
                    { title && <div>{title}</div>}
                    <div className={styles.body}>
                        {visibleFilters.map((filter) => {
                            return (
                                <div key={`${filter.field}`} className={styles.filter}>
                                    {renderFilterField(filter)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};
