import { useState, useEffect } from 'react';
import { Button } from 'react-admin';
import styles from './ListNavigation.module.scss';
import cx from 'classnames';

export const ListNavigation = ({ datasets = {}, activeList, totals = {}, variant = 'button', onNavChange }) => {
    const [activePage, setActivePage] = useState(activeList);

    const formatTotal = (total) => {
        if (total >= 10000) {
            return '(+10000)';
        }
        if (total === undefined) {
            return '';
        }
        return `(${total})`;
    };

    const handleListChange = (page) => {
        setActivePage(page);
        if (onNavChange) {
            onNavChange(page);
        }
    };

    useEffect(() => {
        if (activeList) {
            setActivePage(activeList);
        }
    }, [activeList]);

    if (Object.keys(datasets).length <= 1) {
        return null;
    }

    return (
        <div className={styles.navbar} role="tablist">
            {Object.keys(datasets).map((key, index) => {
                return (
                    <Button
                        key={`nav-${key}`}
                        onClick={() => handleListChange(key)}
                        label={`${datasets[key].name} ${formatTotal(totals[key])}`}
                        className={cx(styles.navItem, {
                            [styles.active]: key === activePage,
                            [styles.button]: variant === 'button',
                            [styles.tab]: variant === 'tab'
                        })}
                        startIcon={datasets[key].icon}
                        role="tab"
                        aria-selected={key === activePage}
                        aria-controls={key}
                        tabIndex={index}
                        variant={'outlined'}
                        size={'medium'}
                    />
                );
            })}
        </div>
    );
};
