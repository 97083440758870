import { formatDate, formatChambre, formatCapitalize } from "../formatters";
import { VoteIcon } from "@logora/parliament.icons.regular_icons";
import { Label } from "@logora/parliament.ui.label"

const formatCode = (field, item) => {
    if(!item.code) return null
    let colorVariants = {
        "success": "adopté",
        "cancel": "rejeté"
    }
    return <Label text={item.code.charAt(0).toUpperCase() + item.code.slice(1)} variantsObject={colorVariants} colorType={item.code} />;
}

export const formatType = (field, item) => {
    let typeObjet = null

    if(item.typeObjet == "amendement") {
        typeObjet = "Amendement"
    } else if(item.typeObjet == "motionCensure") {
        typeObjet = "Motion de censure"
    } else if(item.typeObjet == "motionRejet") {
        typeObjet = "Motion de rejet"
    } else if(item.typeObjet == "motionReferendaire") {
        typeObjet = "Motion référendaire"
    } else if(item.typeObjet == "article") {
        typeObjet = "Article"
    } else if(item.typeObjet == "projetLoi") {
        typeObjet = "Projet de loi"
    } else if(item.typeObjet == "propositionLoi") {
        typeObjet = "Proposition de loi"
    } else if(item.typeObjet == "propositionResolution") {
        typeObjet = "Proposition de résolution"
    }
    return <Label text={typeObjet} />
}

const scrutinsFields = {
    chambre: { label: 'Chambre', field: 'chambre', format: formatChambre },
    dateScrutin: { label: "Date", field: "dateScrutin", sort: "dateScrutin", format: formatDate, defaultSort: true },
    typeObjet: { label: "Type", field: "typeObjet", format: formatType },
    'chambre': { label: 'Chambre', field: 'chambre', format: formatChambre },
    titre: { label: "Objet", field: "titre", format: formatCapitalize },
    nombreVotants: { label: "Votes", field: "nombreVotants" },
    code: { label: "Résultat", field: "code", format: formatCode },
}

const scrutinsFilters = [
    {
        field: "chambre", label: "Chambre", defaultValue: null, type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "AN", label: 'Assemblée' },
            { value: "SN", label: 'Sénat' },
        ]
    },
    { field: "typeObjet", label: 'Type', type: 'select',  options: [
        { value: null , label: "Tous" },
        { value: "amendement", label: "Amendement" },
        { value: "article" , label: "Article" },
        { value: "projetLoi", label: "Projet de loi" },
        { value: "propositionLoi" , label: "Proposition de loi" },
        { value: "motionCensure", label: "Motion de censure" },
        { value: "motionRejet", label: "Motion de rejet" },
        { value: "motionReferendaire", label: "Motion référendaire" },
        { value: "propositionResolution", label: "Proposition de résolution" },
    ]},
    { field: "code", label: 'Résultat', type: 'select',  options: [
        { value: null , label: "Tous" },
        { value: "adopté", label: "Adopté" },
        { value: "rejeté", label: "Rejeté" },
    ]},
    { field: "dateScrutin.gte", label: "Après le", type: "date" },
    { field: "dateScrutin.lte", label: "Avant le", type: "date" },
];

export const scrutinData = {
    key: "scrutins",
    name: "Scrutins",
    filters: scrutinsFilters,
    fields: scrutinsFields,
    icon: <VoteIcon width={20} height={20} />
}