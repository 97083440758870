import { formatDate, formatNumero, formatLabel, formatActeur, formatChambre } from '../formatters'
import { DossierIcon } from '@logora/parliament.icons.regular_icons';

const dossiersFields = {
    chambre: { label: 'Chambre', field: 'chambre', format: formatChambre },
    'dateDernierActe': { label: "Mise à jour", field: "dateDernierActe", sort: "dateDernierActe", defaultSort: true, format: formatDate },
    'typeInitiative': { label: "Type", field: "typeInitiative", format: formatLabel },
    'acteurPrincipalRef': { label: "Auteur", field: "acteurPrincipalRef", include: "acteurPrincipalRef.groupeParlementaire", format: formatActeur },
    'numero': { label: "Numéro", field: "numero", format: formatNumero },
    'titre': { label: "Nom", field: "titre" },
    'statut': { label: "Statut", field: "statut" }
};

const dossiersFilters = [
    {
        field: "chambre", label: "Chambre", type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "AN", label: 'Assemblée' },
            { value: "SN", label: 'Sénat' },
        ]
    },
    { field: "typeInitiative", label: "Type", type: 'select', options: [
        { value: null, label: "Tous" },
        { value: "Proposition de loi" , label: "Proposition de loi" },
        { value: "Projet de loi", label: "Projet de loi" },
        { value: "Commission d'enquête", label: "Commission d'enquête" },
        { value: "Proposition de résolution", label: "Proposition de résolution" },
        { value: "Résolution", label: "Résolution" },
        { value: "Rapport d'information", label: "Rapport d'information" }
    ]},
    { field: "statut", label: "Statut", type: 'select', options: [
        { value: null, label: "Tous" },
        { value: "Texte déposé à l'Assemblée nationale" , label: "Texte déposé à l'Assemblée" },
        { value: "Texte déposé au Sénat", label: "Texte déposé au Sénat" },
        { value: "1ère lecture en commission" , label: "1ère lecture en commission" },
        { value: "2ème lecture en commission", label: "2ème lecture en commission" },
        { value: "1ère lecture en séance publique", label: "1ère lecture en séance publique" },
        { value: "2ème lecture en séance publique", label: "2ème lecture en séance publique" },
        { value: "Texte adopté", label: "Texte adopté" },
        { value: "Discussion du texte de la CMP en séance publique", label: "Discussion du texte de la CMP en séance publique" },
        { value: "Saisine du conseil constitutionnel", label: "Saisine du conseil constitutionnel" }
    ]},
    { field: "dateDepot.gte", label: "Après le", type: "date" },
    { field: "dateDepot.lte", label: "Avant le", type: "date" },
    { field: "numero", label: 'Numéro', type: 'text' }
];

export const dossierData = {
    key: "dossiers",
    name: "Dossiers",
    filters: dossiersFilters,
    filterMapping: { "acteurRefUid": "acteurPrincipalRefUid", "organeRefUid": "acteurPrincipalRef_groupeParlementaireUid" },
    fields: dossiersFields,
    icon: <DossierIcon width={20} height={20} />
}