import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import Tabs from '../components/Tabs';
import { useDataProvider } from 'react-admin';
import { Show } from '../components/show/Show';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { Label } from '@logora/parliament.ui.label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { SenatHemicycle } from '@logora/parliament.parliament_chamber.senat_hemicycle';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { MultiList } from '../search/MultiList';
import { dossierData } from '../dossiers';
import { mandatData } from '../mandats';
import { questionData } from '../questions';
import { amendementData } from '../amendements';
import { interventionData } from '../interventions';
import { reunionData } from '../reunions';
import { documentData } from '../documents';
import { groupesVotantsData } from '../votes';
import { organeData } from './organesFields';
import styles from './OrganeShow.module.scss';
import cloneDeep from 'lodash/cloneDeep';

const organesTravaux = ["COMPER", "COMSENAT", "DELEG", "GP", "GROUPESENAT", "MINISTERE"]

const OrganeDetails = ({ resource, resourceId }) => {
    const [membres, setMembres] = useState([]);
    const dataProvider = useDataProvider();
    const showTravaux = organesTravaux.includes(resource.codeType)

    useEffect(() => {
        if (resource.type === 'Circonscription') {
            setMembres(resource.parlementairesCirconscription);
        } else {
            fetchMembres();
        }
    }, []);

    const getCompositionDataset = () => {
        const mandatsFilters = [
            { field: 'organeRefUid', hidden: true, defaultValue: resource.uid, type: 'text' },
            { field: 'actif', hidden: true, defaultValue: true, label: 'Actif', type: 'text' },
            { field: "nominPrincipale", hidden: true, defaultValue: true, type: 'boolean' }
        ];
    
        return {
            mandats: Object.assign(mandatData, { filters: mandatsFilters })
        }
    }
    
    const getDatasets = () => {
        const dossiersFilters = dossierData.filters.concat([
            {
                field: 'acteurPrincipalRef_groupeParlementaireUid',
                hidden: true,
                defaultValue: resource.uid,
                type: 'text'
            }
        ]);
        const dossiersDataset = Object.assign(cloneDeep(dossierData), { filters: dossiersFilters });

        const dossiersCommissionANFilters = dossierData.filters.concat([
            {
                field: 'commissionANRefUid',
                hidden: true,
                defaultValue: resource.uid,
                type: 'text'
            }
        ]);
        const dossiersCommissionANDataset = Object.assign(cloneDeep(dossierData), { filters: dossiersCommissionANFilters });

        const dossiersCommissionSNFilters = dossierData.filters.concat([
            {
                field: 'commissionSNRefUid',
                hidden: true,
                defaultValue: resource.uid,
                type: 'text'
            }
        ]);
        const dossiersCommissionSNDataset = Object.assign(cloneDeep(dossierData), { filters: dossiersCommissionSNFilters });

        const documentsFilters = documentData.filters.concat([
            {
                field: 'organeRefUid',
                hidden: true,
                defaultValue: resource.uid,
                type: 'text'
            }
        ]);
        const documentsDataset = Object.assign(cloneDeep(documentData), { filters: documentsFilters });

        const questionsFilters = questionData.filters.concat([
            { field: 'acteurRef_groupeParlementaireUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const questionsDataset = Object.assign(cloneDeep(questionData), { filters: questionsFilters });

        const questionsMinistereFilters = questionData.filters.concat([
            { field: 'minIntRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const questionsMinistereDataset = Object.assign(cloneDeep(questionData), { filters: questionsMinistereFilters });

        const interventionsFilters = interventionData.filters.concat([
            { field: 'acteurRef_groupeParlementaireUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const interventionsDataset = Object.assign(cloneDeep(interventionData), { filters: interventionsFilters });

        const amendementsFilters = amendementData.filters.concat([
            { field: 'organeRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const amendementsDataset = Object.assign(cloneDeep(amendementData), { filters: amendementsFilters });

        const organesEnfantsFilters = organeData.filters.concat([
            { field: 'organeParentRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const organesEnfantsDataset = Object.assign(cloneDeep(organeData), { filters: organesEnfantsFilters });

        const reunionsFilters = reunionData.filters.concat([
            { field: 'organeRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
        ]);
        const reunionsDataset = Object.assign(cloneDeep(reunionData), { filters: reunionsFilters });

        if(resource.codeType === "DELEG") {
            return {
                documents: documentsDataset,
                organes: organesEnfantsDataset,
                reunions: reunionsDataset
            }
        } else if(resource.codeType === "COMPER" || resource.codeType === "COMSENAT") {
            return {
                dossiers: resource.codeType === "COMPER" ? dossiersCommissionANDataset : dossiersCommissionSNDataset,
                documents: documentsDataset,
                amendements: amendementsDataset,
                organes: organesEnfantsDataset,
                reunions: reunionsDataset
            }
        } else if(resource.codeType === "MINISTERE") {
            return {
                questions: questionsMinistereDataset
            }
        }
        return {
            dossiers: dossiersDataset,
            amendements: amendementsDataset,
            questions: questionsDataset,
            interventions: interventionsDataset
        };
    };

    const fetchMembres = useCallback(async () => {
        try {
            const response = await dataProvider.getList('mandats', {
                filter: { organeRefUid: resourceId, actif: true, nominPrincipale: true },
                include: ['acteurRef.groupeParlementaire'],
                pagination: { perPage: 1000 }
            });
            const membres = response.data;
            if (membres) {
                setMembres(membres);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    const president = membres?.find((m) => m.codeQualite?.toLowerCase().includes('président'));

    const attributeMemberToHemicyle = (membre) => {
        let formattedMember = {
            placeHemicycle: parseInt(membre?.acteurRef?.placeHemicycle || membre?.placeHemicycle),
            color:
                membre?.acteurRef?.groupeParlementaire?.couleurAssociee ||
                membre?.groupeParlementaire?.couleurAssociee ||
                '#777',
            prenom: membre?.acteurRef?.prenom || membre?.prenom,
            nom: membre?.acteurRef?.nom || membre?.nom,
            urlImage: membre?.acteurRef?.urlImage || membre?.urlImage,
            parliamentGroup: {
                color:
                    membre?.acteurRef?.groupeParlementaire?.couleurAssociee ||
                    membre?.groupeParlementaire?.couleurAssociee,
                groupName: membre?.acteurRef?.groupeParlementaire?.libelle || membre?.groupeParlementaire?.libelle
            }
        };
        return {
            id: formattedMember.placeHemicycle,
            color: formattedMember.color,
            labelBackgroundColor: '#FFF',
            label: (
                <div className={styles.membreOrgane}>
                    <MemberBox
                        firstName={formattedMember.prenom}
                        lastName={formattedMember.nom}
                        imageUrl={formattedMember.urlImage}
                        parliamentGroup={formattedMember.parliamentGroup}
                    />
                </div>
            ),
            position: 'top'
        };
    };

    return (
        <>
            <Box display={'flex'} flexDirection={'column'}>
                <Box className={styles.avatarBackground} display={'flex'} gap={'2em'} sx={{ marginTop: '1em' }}>
                    {resource.urlImage && (
                        <div className={styles.imageWrapper}>
                            <img className={styles.image} src={resource.urlImage}></img>
                        </div>
                    )}
                    <Box display="flex" flexDirection={'column'} marginBottom={'1em'} gap={'0.5em'}>
                        <div className={styles.header}>
                            {resource.chambre && <ChamberLabel chamber={resource.chambre} />}
                            <Label text={resource.type} />
                        </div>
                        <div className={styles.title}>{resource.libelle}</div>
                        <div className={styles.subtitle}>
                            {`Créé le ${new Date(resource.dateDebut).toLocaleDateString()} et ${
                                resource.actif ? 'toujours en activité' : "inactif aujourd'hui"
                            }`}
                        </div>
                    </Box>
                </Box>
                <div className={styles.presentation}>
                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gap="0.5em"
                        sx={{ backgroundColor: '#f9f9f9', padding: '15px', width: '100%' }}
                    >
                        <Box display="flex" flexDirection="column" gap="1em">
                            {resource.positionPolitique && (
                                <Box display="flex" flexDirection="column">
                                    <UiBox className={styles.uiBox} title="Position">
                                        {resource.positionPolitique}
                                    </UiBox>
                                </Box>
                            )}
                            {resource.nombreMembres > 0 && (
                                <Box display="flex" flexDirection="column">
                                    <UiBox className={styles.uiBox} title="Effectif">
                                        <>{resource.nombreMembres} membres</>
                                    </UiBox>
                                </Box>
                            )}
                            {resource.organeParentRef && (
                                <Box display="flex" flexDirection="column">
                                    <UiBox className={styles.uiBox} title="Appartenance">
                                        {resource.organeParentRef.libelle}
                                    </UiBox>
                                </Box>
                            )}
                            {president?.acteurRef && (
                                <Box display="flex" flexDirection="column">
                                    <UiBox className={styles.uiBox} title="Président">
                                        <MemberBox
                                            firstName={president?.acteurRef.prenom}
                                            lastName={president?.acteurRef.nom}
                                            imageUrl={president?.acteurRef.urlImage}
                                        />
                                    </UiBox>
                                </Box>
                            )}
                            {resource.parlementairesCirconscription &&
                                resource.parlementairesCirconscription.length > 0 && (
                                    <Box display="flex" flexDirection="column">
                                        <UiBox className={styles.uiBox} title="Parlementaire actuel">
                                            <MemberBox
                                                firstName={resource.parlementairesCirconscription[0].prenom}
                                                lastName={resource.parlementairesCirconscription[0].nom}
                                                imageUrl={resource.parlementairesCirconscription[0].urlImage}
                                            />
                                        </UiBox>
                                    </Box>
                                )}
                        </Box>
                        <Box flex={2} className={styles.svgContainer}>
                            {resource.chambre === 'AN' && (
                                <AssembleeHemicycle
                                    className={styles.svgIcon}
                                    highlightedSeats={membres?.map(attributeMemberToHemicyle)}
                                />
                            )}

                            {resource.chambre === 'SN' && (
                                <SenatHemicycle highlightedSeats={membres?.map(attributeMemberToHemicyle)} />
                            )}
                        </Box>
                    </Box>
                </div>
            </Box>
            <div className={styles.corps}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            tabData={[
                                showTravaux && {
                                    label: resource.codeType === "MINISTERE" ? 'Questions' : 'Travaux',
                                    content: (
                                        <div role="tabpanel" value={0} index={0}>
                                            <MultiList
                                                datasets={getDatasets()}
                                                showListSearch={true}
                                            />
                                        </div>
                                    )
                                },
                                {
                                    label: 'Composition',
                                    content: (
                                        <div role="tabpanel" value={1} index={1}>
                                            <MultiList
                                                datasets={getCompositionDataset()}
                                                showListSearch={true}
                                            />
                                        </div>
                                    )
                                }
                            ].filter(Boolean)}
                        />
                    </Box>
                </Box>
            </div>
        </>
    );
};

export const OrganeShow = () => {
    return (
        <Show
            resource={'organes'}
            includes={['organeParentRef', 'parlementairesCirconscription']}
            backLink={'/parliament/organes'}
            backText={'Voir tous les organes'}
        >
            <OrganeDetails />
        </Show>
    );
};
