import { formatChambre, formatActeur, formatDate } from '../formatters';
import { SpeechIcon } from '@logora/parliament.icons.regular_icons';
import sanitizeHtml from 'sanitize-html';

const sanitize = (html) => {
    const sanitizeOptions = {
        allowedTags: ['i', 'br']
    };

    let finalHtml = html.replace('<italique>', '<i>');
    finalHtml = finalHtml.replace('</italique>', '</i>');
    const sanitizedHtml = sanitizeHtml(finalHtml, sanitizeOptions);

    return { __html: sanitizedHtml };
};

const interventionsFields = {
    chambre: { label: 'Chambre', field: 'chambre', format: formatChambre },
    dateSeance: {
        label: 'Date',
        field: 'dateSeance',
        include: 'debatRef',
        sort: 'dateSeance',
        defaultSort: true,
        format: formatDate
    },
    acteurRef: {
        label: 'Auteur',
        field: 'acteurRef',
        include: 'acteurRef.groupeParlementaire',
        format: formatActeur
    },
    texte: {
        label: 'Texte',
        field: 'texte',
        format: (field, item) => <p dangerouslySetInnerHTML={sanitize(item[field])} />
    }
};

const interventionsFilters = [
    {
        field: 'chambre',
        label: 'Chambre',
        type: 'select',
        options: [
            { value: null, label: 'Tous' },
            { value: 'AN', label: 'Assemblée' },
            { value: 'SN', label: 'Sénat' }
        ]
    },
    { field: "dateSeance.gte", label: "Après le", type: "date" },
    { field: "dateSeance.lte", label: "Avant le", type: "date" },
    { field: 'uid', label: 'UID', type: 'text' },
    {
        field: 'codeGrammaire',
        label: 'Type',
        type: 'text',
        hidden: true,
        defaultValue: 'PAROLE_GENERIQUE'
    },
    {
        field: 'estPresident',
        label: 'Rôle',
        type: 'text',
        hidden: true,
        defaultValue: false
    },
];

export const interventionData = {
    key: 'interventions',
    name: 'Interventions',
    filters: interventionsFilters,
    filterMapping: { organeRefUid: 'acteurRef_groupeParlementaireUid' },
    fields: interventionsFields,
    icon: <SpeechIcon width={20} height={20} />,
    rowRedirect: (item) => `/parliament/reunions/${item.debatRef.reunionRefUid}#intervention_${item.ordreAbsoluSeance}`
};
