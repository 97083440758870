import { useState, useEffect } from 'react';

const useFetchStats = (dataProvider) => {
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const reduceStats = (stats) => stats.reduce((acc, currentValue) => {
        if (!acc[currentValue["mesure"]]) { acc[currentValue["mesure"]] = { ...currentValue } }
        return acc;
    }, {})

    useEffect(() => {
        const fetchStats = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await dataProvider.getList("stats", {
                    filter: { },
                    pagination: { perPage: 10 }
                });
                const stats = reduceStats(response.data);
                console.log(response.data, stats);
                setStats(stats);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    return { stats, loading, error };
};

export default useFetchStats;