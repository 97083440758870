import React from 'react';
import { CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import { ModerableContentsList } from './moderable_contents/ModerableContentList';
import { AuthPage } from './AuthPage';

export const routes = [
    <CustomRoutes>
        <Route path="/social-networks/contents" element={<ModerableContentsList />} />

        <Route path="/social-networks/:provider/callback" element={<AuthPage />} />
    </CustomRoutes>
];
