import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TextField, Box, IconButton, List, ListItem, ListItemButton } from '@mui/material';
import { useDataProvider } from 'react-admin';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { Label } from '@logora/parliament.ui.label';
import { useLocation } from 'react-router-dom';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import classes from './SearchBar.module.scss';
import cx from 'classnames';

export const SearchBar = ({ onSearchChange, styles, size = 'normal', onUpdateGlobalFilters }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
    const [showCompletions, setShowCompletions] = useState(false);
    const [selectedCompletions, setSelectedCompletions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionType, setSuggestionType] = useState(null);
    const location = useLocation();
    const specialSymbols = {
        '@': {
            model: 'acteurs',
            key: 'acteurRefUid'
        },
        '/': {
            model: 'organes',
            key: 'organeRefUid'
        }
    };
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const prefixCheck = Object.keys(specialSymbols).some((s) => searchQuery.startsWith(s));
        setShowCompletions(prefixCheck && suggestions.length > 0);
    }, [suggestions, searchQuery]);

    useEffect(() => {
        if (Object.keys(specialSymbols).some((s) => searchQuery.startsWith(s))) {
            let type = specialSymbols[searchQuery.slice(0, 1)] || null;
            setSuggestionType(type);
        }
    }, [searchQuery]);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (suggestionType && searchQuery.length > 1) {
                setLoading(true);
                try {
                    const { data } = await dataProvider.getList(suggestionType.model, {
                        search: searchQuery.slice(1),
                        filter: { actif: true },
                        pagination: { page: 1, perPage: 10 }
                    });
                    setSuggestions(data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            } else {
                setSuggestions([]);
            }
        };
        fetchSuggestions();
    }, [searchQuery, dataProvider, suggestionType]);

    useEffect(() => {
        const fetchRecords = async () => {
            const params = new URLSearchParams(location.search);
            const acteurRef = params.get('acteurRefUid');
            const organeRef = params.get('organeRefUid');
            if (acteurRef) {
                try {
                    const response = await dataProvider.getOne('acteurs', { id: acteurRef });
                    const record = response.data;
                    handleCompletionClick(record, { model: 'acteurs', key: 'acteurRefUid' });
                } catch (error) {
                    console.log(error);
                }
            }
            if (organeRef) {
                try {
                    const response = await dataProvider.getOne('organes', { id: organeRef });
                    const record = response.data;
                    handleCompletionClick(record, { model: 'organes', key: 'organeRefUid' });
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchRecords();
    }, [location.search]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value == '') {
            searchParams.delete('query');
            setSearchParams(searchParams);
        }
    };

    const handleResetSearch = (e) => {
        setSearchQuery('');
        setShowCompletions(false);
        setSelectedCompletions([]);
        searchParams.delete('query');
        setSearchParams(searchParams);
        onSearchChange('');
    };

    const handleSearchSubmit = () => {
        if (!searchQuery) {
            searchParams.delete('query');
        }
        onSearchChange(searchQuery.trim());
        setSearchParams((params) => {
            if (searchQuery) {
                params.set('query', searchQuery);
            }
            return params;
        });
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSearchSubmit();
        }
    };

    const handleCompletionClick = (completion, manualType) => {
        const parsedCompletion = {
            filter: { [suggestionType ? suggestionType.key : manualType.key]: completion.uid },
            object: completion,
            model: suggestionType ? suggestionType.model : manualType.model
        };

        setSelectedCompletions((prevSelectedCompletions) => {
            const newSelections = prevSelectedCompletions.filter((c) => c.model !== parsedCompletion.model);
            const updatedSelections = [...newSelections, parsedCompletion];
            if (onUpdateGlobalFilters) {
                onUpdateGlobalFilters(updatedSelections);
            }
            return updatedSelections;
        });

        setSearchParams((params) => {
            if (parsedCompletion) {
                params.set(`${Object.keys(parsedCompletion.filter)[0]}`, Object.values(parsedCompletion.filter)[0]);
            }
            return params;
        });

        setSearchQuery(searchParams.get('query') || '');
    };

    const clearFilter = (indexToRemove) => {
        const newSelectedCompletions = selectedCompletions.filter((_, index) => index !== indexToRemove);
        searchParams.delete(Object.keys(selectedCompletions[indexToRemove].filter));
        setSearchParams(searchParams);
        setSelectedCompletions(newSelectedCompletions);
        if (onUpdateGlobalFilters) {
            onUpdateGlobalFilters(newSelectedCompletions);
        }
    };

    const renderLabelFilter = (completion) => {
        if (!completion) {
            return null;
        }
        switch (completion.model) {
            case 'acteurs':
                return (
                    <MemberBox
                        firstName={completion.object?.prenom}
                        lastName={completion.object?.nom}
                        imageUrl={completion.object?.urlImage}
                        size="small"
                        layout="oneLine"
                    />
                );
            case 'organes':
                return (
                    <Box sx={{ display: 'flex', gap: '.5em' }}>
                        {completion.object?.chambre && (
                            <>
                                <ChamberLabel
                                    short
                                    chamber={completion.object?.chambre}
                                    className={classes.searchLabel}
                                />
                                <Box variant="span" className={classes.searchLabel}>
                                    ·
                                </Box>
                            </>
                        )}
                        <Label
                            className={cx(classes.searchLabel, classes.organeSearchLabel)}
                            text={`${completion.object?.libelle.slice(0, 85)} ${
                                completion.object?.libelle.length > 85 ? '...' : ''
                            }`}
                            style={{ whiteSpace: 'wrap' }}
                        />
                    </Box>
                );
            default:
                return completion.object?.libelle;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '60%', ...styles }}>
            <div
                className={cx(classes.searchContainer, {
                    [classes.large]: size !== 'small',
                    [classes.small]: size === 'small'
                })}
            >
                <div className={classes.completionContainer}>
                    {selectedCompletions.map((completion, index) => (
                        <Box
                            key={index}
                            className={classes.searchFilter}
                            sx={{ margin: '4px 8px 4px 0', display: 'inline-flex' }}
                        >
                            {renderLabelFilter(completion)}
                            <IconButton size="small" onClick={() => clearFilter(index)} sx={{ padding: 0 }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    ))}
                </div>
                <div className={classes.inputContainer}>
                    <TextField
                        variant="standard"
                        placeholder="Rechercher un sujet, un député, un vote, un amendement..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                        size={size}
                        InputProps={{ disableUnderline: true, className: classes.searchField }}
                        sx={{ flex: 1 }}
                    />
                    <IconButton onClick={handleResetSearch} sx={{ marginLeft: '8px' }}>
                        {searchQuery.length === 0 && selectedCompletions.length === 0 ? <SearchIcon /> : <CloseIcon />}
                    </IconButton>
                </div>
            </div>
            {showCompletions && !loading && (
                <List
                    sx={{
                        border: '1px solid rgba(0, 0, 0, 0.2)',
                        borderRadius: 1,
                        bgcolor: 'background.paper',
                        position: 'absolute',
                        width: '100%',
                        top: '100%',
                        zIndex: '99999',
                        overflow: 'hidden'
                    }}
                >
                    {suggestions.map((suggestion, index) => (
                        <ListItem key={index}>
                            <ListItemButton onClick={() => handleCompletionClick(suggestion)}>
                                {suggestionType?.model === 'acteurs' ? (
                                    <MemberBox
                                        firstName={suggestion.prenom}
                                        lastName={suggestion.nom}
                                        imageUrl={suggestion.urlImage}
                                        size="small"
                                        layout="oneLine"
                                    />
                                ) : suggestionType?.model === 'organes' ? (
                                    <Box sx={{ display: 'flex', gap: '.5em' }}>
                                        {suggestion.chambre && (
                                            <>
                                                <ChamberLabel short chamber={suggestion.chambre} />
                                                <Box variant="span" className={classes.searchLabel}>
                                                    ·
                                                </Box>
                                            </>
                                        )}
                                        <Label
                                            text={`${suggestion.libelle.slice(0, 85)} ${
                                                suggestion.libelle.length > 85 ? '...' : ''
                                            }`}
                                            style={{ whiteSpace: 'wrap' }}
                                        />
                                    </Box>
                                ) : null}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};
